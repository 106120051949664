body {
  margin: 0;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/images/raster/background.png");
  background-size: fill;
  background-repeat: no-repeat;
  color: "#FFF";
  background-color: "#000";
}
@font-face {
  font-family: "d Diam";
  src: local("d Diam"), url(./assets/fonts/diam.otf) format("opentype");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
